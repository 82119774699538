<template lang='pug'>
.main-container
  .view-content
    el-card(v-loading="loading")
      div(slot='header')
        i.mat-icon account_circle
        | Account Details

      account-edit(
        :account-id="$route.params.id"
      )

      account-customers(
        :account-id="$route.params.id"
      ) 
</template>

<script>
import AccountEdit from './blocks/AccountEdit.vue'
import AccountCustomers from './blocks/AccountCustomers.vue'

/**
 * AccountDetail component is responsible for displaying and
 * managing the account details view. It includes the AccountEdit
 * component and handles loading state.
 */
export default {
  name: 'AccountDetail',

  components: {
    AccountEdit,
    AccountCustomers
  },

  data() {
    return {
      loading: false,
    }
  },
}
</script>
