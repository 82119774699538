<template>
    <div class="user-salutation">
        <div 
            class="salutation" 
            v-for="salutation in salutations" 
            :key="salutation"
            :class="{['active-item']: activeSalutation === salutation}"
            @click="setSalutation(salutation)"
        >
        {{salutation}}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        address: {
            require: true
        },
        initialState: {
            require: false
        }
    },
    data() {
        return {
           salutations: ['Ms.', 'Mr.', 'Dr.'],
           activeSalutation: this.initialState
        }
    },
    methods: {
        setSalutation(salutation) {
            this.activeSalutation = salutation
            this.$emit('onSalitationClick', salutation)
        }
    }
}
</script>

<style scoped lang="sass">
.user-salutation
    height: 3rem
    border-radius: .75rem
    display: flex
.salutation
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    border: 1px solid #E8E8E3
    cursor: pointer
    &:last-child
      border-radius: 0 .75rem .75rem 0
    &:first-child
      border-radius: .75rem 0  0 .75rem
    &.active-item
      border-color: #125F43
      color: #125F43
      background: #F4F9F5   
</style>