<template lang="pug">
.main-container
  .view-content
    el-card
      div(slot='header')
        i.mat-icon redeem
        | Rewards
      el-row(:gutter='10', class="filtering")
        el-col(:span='9')
          el-input(v-model='filterAccount' placeholder="Search by name" )
      el-table(:data='accounts', v-loading='accounts == null')
        el-table-column(label='Account name')
          template(slot-scope='scope')
            router-link(:to="'/staff/rewards/' + scope.row.id") {{ scope.row.name }}

      el-col
        el-pagination( 
          v-if="pagination.pages > 1"
          @current-change="onPaginationChange"
          :page-size="pagination.per_page", 
          :pager-count="5", 
          layout="prev, pager, next", 
          :total="pagination.total")

  router-view
</template>

<script>
export default {
  name: 'AccountsWithRewards',

  data() {
    return {
      accounts: null,
      filterAccount: '',
      pagination: {
        per_page: null,
        current: 1,
        pages: null,
        total: null,
      }
    }
  },

  methods: {
    async getAccounts(current_page) {
      !!this.accounts && (this.accounts = null);

      let params = current_page ? { params: { page: current_page } } : {};

      const url = this.filterAccount.trim() ? `/points/accounts/?q=${this.filterAccount.trim()}` : "/points/accounts/";

      const { data, pagination } = (
        await this.$simplecater.get(url, params)
      ).data;

      this.accounts = data;
      this.pagination = pagination;
    },

    onPaginationChange(current_page) {
      this.getAccounts(current_page);
    }
  },

  watch: {
    filterAccount() {
      this.getAccounts();
    }
  },

  created() {
    this.getAccounts(this.pagination.current);
  }
}
</script>