<template>
    <div class="user-address">
        <div class="user-address-left-section">
            <div class="svg-container">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#F5F2D0"/>
            <path d="M20 21C21.6569 21 23 19.6569 23 18C23 16.3431 21.6569 15 20 15C18.3431 15 17 16.3431 17 18C17 19.6569 18.3431 21 20 21Z" stroke="#CFA531" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20 30C24 26 28 22.4183 28 18C28 13.5817 24.4183 10 20 10C15.5817 10 12 13.5817 12 18C12 22.4183 16 26 20 30Z" stroke="#CFA531" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </div>
            <div>
            <div>
            {{address.name || 'Address'}}
            </div>
            <div class="description-text desc-info">{{address.city}}, {{address.state}} <info-btn :alertInfo="`${address.address_line_1}, ${address.address_line_2 ? address.address_line_2 + ',' : ''} ${address.city}, ${address.state}, ${address.zip_code}`"/></div>
            </div>
        </div>
        <div class="user-address-info address-column">
            <div>{{address.address_line_1}}</div>
            <div class="description-text">{{address.address_line_2}}</div>
        </div>
        <div class="user-address-zip-code address-column">
            <div class="description-text">Zip Code</div>
            <div>{{address.zip_code}}</div>
        </div>
        <div class="user-address-actions address-column">
            <edit-button @onClick="$emit('editClick', address.id)"/>
            <div class="delete-address" @click="$emit('deleteClick', address.id)">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6667 3.99992V3.46659C10.6667 2.71985 10.6667 2.34648 10.5213 2.06126C10.3935 1.81038 10.1895 1.60641 9.93865 1.47858C9.65344 1.33325 9.28007 1.33325 8.53333 1.33325H7.46667C6.71993 1.33325 6.34656 1.33325 6.06135 1.47858C5.81046 1.60641 5.60649 1.81038 5.47866 2.06126C5.33333 2.34648 5.33333 2.71985 5.33333 3.46659V3.99992M6.66667 7.66659V10.9999M9.33333 7.66659V10.9999M2 3.99992H14M12.6667 3.99992V11.4666C12.6667 12.5867 12.6667 13.1467 12.4487 13.5746C12.2569 13.9509 11.951 14.2569 11.5746 14.4486C11.1468 14.6666 10.5868 14.6666 9.46667 14.6666H6.53333C5.41323 14.6666 4.85318 14.6666 4.42535 14.4486C4.04903 14.2569 3.74307 13.9509 3.55132 13.5746C3.33333 13.1467 3.33333 12.5867 3.33333 11.4666V3.99992" stroke="#667085" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Delete
            </div>
        </div>
    </div>
</template>

<script>
import EditButton from './EditButton.vue'
import InfoBtn from './InfoBtn.vue'

export default {
    components: {  EditButton, InfoBtn },
    props: {
        address: {
            require: true
        }
    },
}
</script>

<style scoped lang="sass">
.user-address
    background: #FFFFFF
    border-radius: 1rem
    box-shadow: 0px .5rem 2.5rem -.25rem rgba(31, 31, 24, 0.03)
    padding: 1.5rem
    display: flex
    gap: 2.5rem
    align-items: center
    font-size: .875rem
    margin-bottom: 1rem
    justify-content: space-between
    &-left-section
        display: flex
        gap: 1.25rem
        align-items: center
        flex: 0 0 25%
    &-actions
        display: flex    
.delete-address
    cursor: pointer
    display: flex
    aling-items: centr
    gap: .5rem
.description-text
    font-size: .875rem
.address-column
    flex: 0 0 20%
.desc-info
    display: flex
    gap: 8px
    align-items: center
    white-space: nowrap
.svg-container
    min-width: 2.625rem       
    
</style>