<template lang='pug'>
  .main-container
    .view-content
      el-card
        div(slot='header')
          i.mat-icon group
          | Accounts
          el-button(type="primary", @click="startCreatingAccount", style='float: right')
            i.mat-icon add_circle
            | New account
        
        el-col(:span="9")
          el-input(class="el-col-9", v-model="searchAccounts", placeholder="Search by name")
  
        el-table(:data='accounts', v-loading='accounts == null')
          el-table-column(label='Name')
            template(slot-scope='scope')
              router-link(:to="'/staff/accounts/' + scope.row.id") {{ scope.row.name }}
        
        el-col()
          el-pagination( 
            v-if="pagination.pages > 1"
            @current-change="onPaginationChange"
            :page-size="pagination.per_page", 
            :pager-count="5", 
            layout="prev, pager, next", 
            :total="pagination.total")

      el-dialog(:visible.sync='creatingAccount', title='Create account')
        el-form(ref="newAccount", :model="newAccount", label-width="150px", v-if='newAccount != null', :rules="createAccountRules")
          el-form-item(label='Name', prop="name")
            el-input(placeholder="", v-model='newAccount.name')
          el-form-item(align="right")
            el-button(type='primary', @click='submitForm')
              | Create account
  </template>

<script>
export default {
  name: 'AccountsList',

  data() {
    return {
      accounts: null,
      searchAccounts: '',
      pagination: {
        per_page: null,
        current: null,
        pages: null,
        total: null,
      },
      creatingAccount: false,
      newAccount: null,
      createAccountRules: {
        name: [
          {
            required: true,
            message: "Please input the account name",
            trigger: "blur"
          },
          {
            min: 2,
            message: "Length should be at least 2 characters",
            trigger: "blur"
          }
        ]
      }
    }
  },

  methods: {
    async getAccounts(current_page) {
      !!this.accounts && (this.accounts = null);
      let params = current_page ? { params: { page: current_page } } : {};
      const url = this.searchAccounts.trim() ? `/accounts?q=${this.searchAccounts.trim()}` : "/accounts";

      const { data, pagination } = (
        await this.$simplecater.get(url, params)
      ).data;
      this.accounts = data;
      this.pagination = pagination;
    },

    onPaginationChange(current_page) {
      this.getAccounts(current_page);
    },

    startCreatingAccount() {
      this.creatingAccount = true;
      this.newAccount = {
        name: ''
      };
    },

    async submitForm() {
      try {
        await this.$refs.newAccount.validate();
        await this.$simplecater.post('/accounts', this.newAccount);
        this.$message.success('Account created successfully');
        this.creatingAccount = false;
        await this.getAccounts();
      } catch (error) {
        this.$message.error(error.response?.data?.message || 'Failed to create account');
      }
    },
  },

  watch: {
    searchAccounts() {
      this.getAccounts();
    }
  },

  created() {
    this.getAccounts()
  }
}
</script>

<style lang="scss" scoped>
</style>