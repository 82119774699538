<template>
<!-- @mouseleave="hideActive()"-->
<div class="info-btn" @mouseover="setActive()" @mouseleave="hideActive()">
    <svg v-if="!active" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99961 8.0001L7.99961 11.2001M7.99961 5.62822V5.6001M1.59961 8.0001C1.59961 4.46547 4.46499 1.6001 7.99961 1.6001C11.5342 1.6001 14.3996 4.46548 14.3996 8.0001C14.3996 11.5347 11.5342 14.4001 7.99961 14.4001C4.46499 14.4001 1.59961 11.5347 1.59961 8.0001Z" stroke="#A2A29D" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-else  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99961 8.0001L7.99961 11.2001M7.99961 5.62822V5.6001M1.59961 8.0001C1.59961 4.46547 4.46499 1.6001 7.99961 1.6001C11.5342 1.6001 14.3996 4.46548 14.3996 8.0001C14.3996 11.5347 11.5342 14.4001 7.99961 14.4001C4.46499 14.4001 1.59961 11.5347 1.59961 8.0001Z" stroke="#2BB673" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <div class="alert-arrow" v-if="active">
    </div>
    <div class="alert-info" v-if="active" :style="{minWidth: width}">
         {{alertInfo}}
    </div>
</div> 
</template>

<script>
export default {
    data() {
        return {
            active: false
        }
    },

    props: {
        alertInfo: {
            type: String
        },

        width: {
            type: String,
            default: 'fit-content'
        }
    },

    methods: {
        setActive() {
            this.active = true
        },

        hideActive() {
            this.active = false
        }
    }
}
</script>


<style scoped lang="sass">
.info-btn
    position: relative
    display: flex
    align-items: center
    min-width: fit-content
.alert-info
    position: absolute
    background: #FAFAF5
    padding: .75rem .4375rem
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08)
    border-radius: .5rem
    height: auto
    width: 100%
    //width: 400px
    display: flex
    align-items: center
    left: 1.875rem
    justify-content: center
    color: #111928
    font-size: .875rem
.alert-arrow
    position: absolute
    left: 23px
    top: 0px
    transform: rotate(0deg)
    width: 0
    height: 0
    border-top: 10px solid transparent
    border-bottom: 10px solid transparent
    border-right:10px solid #FAFAF5 
</style>