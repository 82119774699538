<template>
    <div class="user-detail">
        <div class="user-detail-left-section">
            <div class="user-detail-initials">{{initials}}</div>
            <div>
                <div class="info-value">{{ full_name || 'Unknown' }}</div>
            </div>
        </div>
        <div class="user-detail-info detail-column">
            <div class="description-text">Email</div>
            <div class="info-value">{{user.email}}</div>
        </div>
        <div class="user-detail-phone detail-column">
            <div class="description-text">Phone</div>
            <div class="info-value">+1 {{modifiedPhoneNumber(user.contact_phone_number)}}</div>
        </div>
        <div class="user-detail-actions detail-column">
            <edit-button @onClick="$emit('editClick', user.id)"/>
            <div class="delete-user" @click="$emit('deleteClick', user.id)">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.6667 3.99992V3.46659C10.6667 2.71985 10.6667 2.34648 10.5213 2.06126C10.3935 1.81038 10.1895 1.60641 9.93865 1.47858C9.65344 1.33325 9.28007 1.33325 8.53333 1.33325H7.46667C6.71993 1.33325 6.34656 1.33325 6.06135 1.47858C5.81046 1.60641 5.60649 1.81038 5.47866 2.06126C5.33333 2.34648 5.33333 2.71985 5.33333 3.46659V3.99992M6.66667 7.66659V10.9999M9.33333 7.66659V10.9999M2 3.99992H14M12.6667 3.99992V11.4666C12.6667 12.5867 12.6667 13.1467 12.4487 13.5746C12.2569 13.9509 11.951 14.2569 11.5746 14.4486C11.1468 14.6666 10.5868 14.6666 9.46667 14.6666H6.53333C5.41323 14.6666 4.85318 14.6666 4.42535 14.4486C4.04903 14.2569 3.74307 13.9509 3.55132 13.5746C3.33333 13.1467 3.33333 12.5867 3.33333 11.4666V3.99992" stroke="#667085" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Delete
            </div>
        </div>
    </div>
</template>

<script>
import EditButton from './EditButton.vue'
import modifyPhoneNumber from '@/mixins/modifiedPhoneNumber'

export default {
    components: { EditButton },
    props: {
        user: {
            type: Object,
            required: true
        }
    },

    computed: {
        initials() {
            const firstName = this.user.contact_first_name || '';
            const lastName = this.user.contact_last_name || '';
            const firstInitial = firstName.length > 0 ? firstName[0].toUpperCase() : '';
            const lastInitial = lastName.length > 0 ? lastName[0].toUpperCase() : '';
            return firstInitial + lastInitial || '??';
        },
        full_name() {
            return `${this.user.contact_first_name || ''} ${this.user.contact_last_name || ''}`
        }
    },
    mixins: [modifyPhoneNumber],
}
</script>

<style scoped lang="sass">
.user-detail
    background: #FFFFFF
    border-radius: 1rem
    box-shadow: 0px .5rem 2.5rem -.25rem rgba(31, 31, 24, 0.03)
    padding: 1.5rem
    display: flex
    gap: 2.5rem
    align-items: center
    font-size: .875rem
    margin-bottom: 1rem
    justify-content: space-between
    &-left-section
        display: flex
        gap: 1.25rem
        align-items: center
        flex: 0 0 25%
    &-actions
        display: flex
    &-initials
      width: 2.5rem
      height: 2.5rem
      color: #CFA531
      border-radius: 100%
      font-size: 14p
      background: #F5F2D0
      display: flex
      justify-content: center
      align-items: center
.delete-user
    cursor: pointer
    display: flex
    align-items: center
    gap: .5rem
.description-text
    font-size: 14px
    color: #70706B
.info-value
    color: #1F1F18
.detail-column
    flex: 0 0 20%
.svg-container
    min-width: 2.625rem
</style>
