<template>
  <div class="main-container">
    <div class="view-content">
      <div class="users-header">
        <h2 class="page-title">Users</h2>
        <default-button @onClick="startCreatingUser" :style="{ marginTop: '1.2rem', fontSize: '.875rem' }">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00065 3.33325V12.6666M3.33398 7.99992H12.6673" stroke="white" stroke-width="1.33333"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          New User
        </default-button>
      </div>

      <el-input class="search" v-model="search" prefix-icon="el-icon-search" placeholder="Search users..."></el-input>

      <div :data="users" v-loading="users == null">
        <user-detail-card v-for="user in users" :key="user.id" :user="user" @editClick="onEditClick"
          @deleteClick="deleteUser" />

        <!-- 
      <el-col>
        <el-pagination 
          v-if="pagination.pages > 1"
          @current-change="onPaginationChange"
          :page-size="pagination.per_page"
          :pager-count="5"center
      </el-col> -->
      </div>

      <el-dialog :visible.sync='creatingUser' title='Create user' custom-class="rounded-dialog">
        <el-form ref="newUser" :model="newUser" label-width="150px" label-position="top" v-if='newUser != null'
          :rules="createUserRules">
          <p>Create new user account</p>

          <el-form-item label="Salutation" prop="contact_salutation">
            <user-salutation-switch @onSalitationClick="newUser.contact_salutation = $event"
              :initial-state="newUser.contact_salutation" />
          </el-form-item>

          <el-form-item label='First Name' prop="contact_first_name">
            <el-input placeholder="" v-model='newUser.contact_first_name' />
          </el-form-item>

          <el-form-item label='Last Name' prop="contact_last_name">
            <el-input placeholder="" v-model='newUser.contact_last_name' />
          </el-form-item>

          <el-form-item label="Phone Number" prop="contact_phone_number">
            <el-input v-model="newUser.contact_phone_number"><template slot="prepend">+1</template></el-input>
          </el-form-item>

          <el-form-item label='Email' prop="email">
            <el-input placeholder="" v-model='newUser.email'>
              <template slot="prepend"><i class="mat-icon">email</i></template>
            </el-input>
          </el-form-item>

          <el-form-item label='Password' prop="password">
            <el-input placeholder="" v-model='newUser.password' type="password" />
          </el-form-item>

          <el-form-item align="right">
            <default-button title="Create user" @onClick="submitForm">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.66699 8H13.3337M13.3337 8L9.33366 4M13.3337 8L9.33366 12" stroke="white"
                  stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </default-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog :visible.sync='editingUser' title='User Information' custom-class="rounded-dialog">
        <el-form ref="editUserForm" :model="editUserData" label-width="150px" label-position="top"
          v-if='editUserData != null' :rules="editUserRules">
          <p>Details information about user</p>

          <el-form-item label="Salutation" prop="contact_salutation">
            <user-salutation-switch @onSalitationClick="editUserData.contact_salutation = $event"
              :initial-state="editUserData.contact_salutation" />
          </el-form-item>
          <el-form-item label='First Name' prop="contact_first_name">
            <el-input placeholder="" v-model='editUserData.contact_first_name' />
          </el-form-item>
          <el-form-item label='Last Name' prop="contact_last_name">
            <el-input placeholder="" v-model='editUserData.contact_last_name' />
          </el-form-item>

          <el-form-item label='Email' prop="email">
            <el-input placeholder="" v-model='editUserData.email'>
              <template slot="prepend"><i class="mat-icon">email</i></template>
            </el-input>
          </el-form-item>
          <el-form-item label="Phone Number" prop="contact_phone_number">
            <el-input v-model="editUserData.contact_phone_number"><template slot="prepend">+1</template></el-input>
          </el-form-item>

          <p style="margin-top: 32px;">User Permissions</p>

          <div class="user-detail">
            <div class="permissions-container">
              <div class="permission-row">
                <custome-checkbox v-model="user_permissions.worldwide_view" :squareShapes="true"
                  :initialValue="user_permissions.worldwide_view" title="Worldwide View" />
                <div class="info-btn">
                  <el-tooltip class="item" effect="light" :content="permissionInfo['worldwide_view']" placement="right">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.99961 8.0001L7.99961 11.2001M7.99961 5.62822V5.6001M1.59961 8.0001C1.59961 4.46547 4.46499 1.6001 7.99961 1.6001C11.5342 1.6001 14.3996 4.46548 14.3996 8.0001C14.3996 11.5347 11.5342 14.4001 7.99961 14.4001C4.46499 14.4001 1.59961 11.5347 1.59961 8.0001Z"
                        stroke="#A2A29D" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </el-tooltip>
                </div>
              </div>
              <div class="permission-row" v-for="(value, key) in user_permissions.permissions" :key="key">
                <custome-checkbox v-model="user_permissions.permissions[key]" :squareShapes="true"
                  :initialValue="user_permissions.permissions[key]" :title="formatPermissionName(key)" />
                <div class="info-btn">
                  <el-tooltip class="item" effect="light" :content="permissionInfo[key]" placement="right">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.99961 8.0001L7.99961 11.2001M7.99961 5.62822V5.6001M1.59961 8.0001C1.59961 4.46547 4.46499 1.6001 7.99961 1.6001C11.5342 1.6001 14.3996 4.46548 14.3996 8.0001C14.3996 11.5347 11.5342 14.4001 7.99961 14.4001C4.46499 14.4001 1.59961 11.5347 1.59961 8.0001Z"
                        stroke="#A2A29D" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>

          <el-form-item class="edit-form-footer">
            <div class="left">
              <div class="delete-user" @click="deleteUserFromEditForm(editUserData.id)">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.6667 4.00004V3.46671C10.6667 2.71997 10.6667 2.3466 10.5213 2.06139C10.3935 1.8105 10.1895 1.60653 9.93865 1.4787C9.65344 1.33337 9.28007 1.33337 8.53333 1.33337H7.46667C6.71993 1.33337 6.34656 1.33337 6.06135 1.4787C5.81046 1.60653 5.60649 1.8105 5.47866 2.06139C5.33333 2.3466 5.33333 2.71997 5.33333 3.46671V4.00004M6.66667 7.66671V11M9.33333 7.66671V11M2 4.00004H14M12.6667 4.00004V11.4667C12.6667 12.5868 12.6667 13.1469 12.4487 13.5747C12.2569 13.951 11.951 14.257 11.5746 14.4487C11.1468 14.6667 10.5868 14.6667 9.46667 14.6667H6.53333C5.41323 14.6667 4.85318 14.6667 4.42535 14.4487C4.04903 14.257 3.74307 13.951 3.55132 13.5747C3.33333 13.1469 3.33333 12.5868 3.33333 11.4667V4.00004"
                    stroke="#D92D20" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Delete
              </div>
            </div>
            <div class="right">
              <el-button class="cancel-button" @click="cancelEdit" style="margin-right: 10px">Cancel</el-button>
              <default-button title="Save" @onClick="submitEditForm" class="el-button">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.66699 8H13.3337M13.3337 8L9.33366 4M13.3337 8L9.33366 12" stroke="white"
                    stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </default-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>

    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import DefaultButton from '@/layout/Blocks/DefaultButton.vue'
import UserDetailCard from './blocks/UserDetailCard.vue'
import UserSalutationSwitch from './blocks/UserSalutationSwitch.vue'
import CustomeCheckbox from './blocks/CustomeCheckbox.vue'

export default {
  name: 'UsersList',

  components: {
    DefaultButton,
    UserDetailCard,
    UserSalutationSwitch,
    CustomeCheckbox,
  },

  data() {
    return {
      customer: null,
      users: null,
      search: '',
      creatingUser: false,
      editingUser: false,
      newUser: null,
      editUserData: null,
      pagination: {
        per_page: null,
        current: null,
        pages: null,
        total: null,
      },
      createUserRules: {
        email: [
          {
            required: true,
            message: "Please input the email",
            trigger: "blur"
          },
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"]
          }
        ],
        password: [
          {
            required: true,
            message: "Please input the password",
            trigger: "blur"
          }
        ],
        contact_first_name: [
          {
            required: true,
            message: "Please input the first name",
            trigger: "blur"
          }
        ],
        contact_last_name: [
          {
            required: true,
            message: "Please input the last name",
            trigger: "blur"
          }
        ],
        contact_phone_number: [
          {
            required: true,
            message: "Please input the phone number",
            trigger: "blur"
          }
        ],
        contact_salutation: [
          {
            required: true,
            message: 'Please select an option',
            trigger: 'change'
          }
        ],
      },
      editUserRules: {
        email: [
          {
            required: true,
            message: "Please input the email",
            trigger: "blur"
          },
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"]
          }
        ],
        contact_first_name: [
          {
            required: true,
            message: "Please input the first name",
            trigger: "blur"
          }
        ],
        contact_last_name: [
          {
            required: true,
            message: "Please input the last name",
            trigger: "blur"
          }
        ],
        contact_phone_number: [
          {
            required: true,
            message: "Please input the phone number",
            trigger: "blur"
          }
        ],
        contact_salutation: [
          {
            required: true,
            message: 'Please select an option',
            trigger: 'change'
          }
        ],
      },
      editingId: null,
      user_permissions: {
        worldwide_view: false,
        permissions: {}
      },
      permissionInfo: {
        worldwide_view: "Allows user to access and view all activity for the account. (Requests, Orders, Invoices)",
        access_points: "Allows user to redeem account points for rewards. (Amazon or Catering Credit)"
      },
    }
  },

  watch: {
    customer: {
      immediate: true,
      handler(newAccount) {
        if (newAccount) {
          this.getUsers();
        }
      }
    },
    search: function () {
      this.getUsers();
    },
  },

  methods: {
    async getCustomer() {
      await this.$simplecater.get('/account').then((r) => {
        this.customer = r.data.data
      }).catch(() => {
        this.$message.error("Failed to load the user information")
      })
    },

    async getUsers(current_page) {
      this.users = null;
      let reqParams = current_page ? { params: { page: current_page } } : { params: {} };
      if (this.search) {
        reqParams.params.q = this.search
      }
      try {
        const { data, pagination } = (await this.$simplecater.get('/accounts/' + this.customer.account_id + '/customers', reqParams)).data;
        this.users = data;
        this.pagination = pagination;
      } catch (error) {
        this.$message.error('Failed to load users');
      }
    },

    startCreatingUser() {
      this.creatingUser = true;
      this.newUser = {
        account_name: '',
        email: '',
        password: '',
        contact_salutation: '',
        contact_first_name: '',
        contact_last_name: '',
        contact_phone_number: '',
      };
    },

    async submitForm() {
      try {
        await this.$refs.newUser.validate();
        this.newUser.account_name = this.newUser.contact_first_name + ' ' + this.newUser.contact_last_name;
        const target = `/accounts/${this.customer.account_id}/customers/`
        await this.$simplecater.post(target, this.newUser);

        this.$message.success('User created successfully');
        this.creatingUser = false;

        await this.getUsers();
      } catch (error) {
        this.$message.error(error.response?.data?.error?.reason || 'Failed to create user');
      }
    }, 
    
    getOrdersChange(current_page) {
      this.getUsers(current_page);
    },

    onEditClick(row) {
      this.editingUser = true;
      const user = this.users.find(user => user.id === row);

      this.editUserData = {
        id: user.id,
        account_name: user.account_name,
        email: user.email,
        contact_salutation: user.contact_salutation,
        contact_first_name: user.contact_first_name,
        contact_last_name: user.contact_last_name,
        contact_phone_number: user.contact_phone_number
      };

      this.getPermissionsList().then(() => {
        this.getUserPermissions(user.id);
      });
    },

    async submitEditForm() {
      try {
        await this.$refs.editUserForm.validate();

        if (this.editUserData.account_name === '') {
          this.editUserData.account_name = this.editUserData.contact_first_name + ' ' + this.editUserData.contact_last_name;
        }

        const target = `/accounts/${this.customer.account_id}/customers/${this.editUserData.id}`;
        await this.$simplecater.put(target, this.editUserData);
        await this.savePermissions();

        this.$message.success('User updated successfully');
        this.editingUser = false;
        this.editUserData = null;
        this.getUsers();
      } catch (error) {
        this.$message.error(error.response?.data?.error?.reason || 'Failed to update user');
      }
    },

    async savePermissions() {
      const target = `/accounts/${this.customer.account_id}/customers/${this.editUserData.id}/permissions`;
      try {
        await this.$simplecater.put(target, this.user_permissions);
      } catch (error) {
        this.$message.error("Failed to save permissions");
      }
    },

    cancelEdit() {
      this.editingUser = false;
      this.editUserData = null;
    },

    async deleteUser(id) {
      try {
        await this.$simplecater.delete(`/accounts/${this.customer.account_id}/customers/${id}`);
        this.$message.success("The user has been deleted");
        await this.getUsers();
      } catch (error) {
        this.$message.error("Failed to delete user");
      }
    },

    async deleteUserFromEditForm(id) {
      try {
        await this.$simplecater.delete(`/accounts/${this.customer.account_id}/customers/${id}`);

        this.$message.success("The user has been deleted");
        this.editingUser = false;
        this.editUserData = null;

        await this.getUsers();
      } catch (error) {
        this.$message.error("Failed to delete user");
      }
    },

    async getPermissionsList() {
      const target = `/accounts/${this.customer.account_id}/customers/permissions-list`

      await this.$simplecater.get(target).then((r) => {
        const permissions = r.data.data.reduce((acc, permission) => {
          if (permission === 'worldwide_view') {
            this.user_permissions.worldwide_view = false;
          } else {
            acc[permission] = false;
          }
          return acc;
        }, {});

        this.user_permissions = {
          worldwide_view: false,
          permissions
        };
      }).catch(() => {
        this.$message.error("Failed to load the user permissions")
      })
    },

    async getUserPermissions(userId) {
      const target = `/accounts/${this.customer.account_id}/customers/${userId}/permissions`;
      await this.$simplecater.get(target).then((r) => {
        const userPerms = r.data.data;
        if (userPerms.worldwide_view !== undefined) {
          this.user_permissions.worldwide_view = userPerms.worldwide_view;
        }
        if (userPerms.permissions) {
          Object.keys(userPerms.permissions).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(this.user_permissions.permissions, key)) {
              this.user_permissions.permissions[key] = userPerms.permissions[key];
            }
          });
        }
      }).catch(() => {
        this.$message.error("Failed to load the user permissions");
      });
    },

    formatPermissionName(key) {
      return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
  },

  created() {
    this.getCustomer();
  }
}
</script>

<style lang="sass" scoped>
.users-header
  display: flex
  justify-content: space-between
  align-items: flex-start

.inline-form
  .el-form-item
    margin-bottom: 10px
  .el-button
    margin-right: 10px

.edit-icon
  font-size: 16px
  margin-left: 8px
  cursor: pointer
  vertical-align: sub

.search
  width: 23rem
  margin-bottom: 28px

.permissions-container
  display: flex
  flex-direction: row
  align-items: center
  justify-content: flex-start
  gap: 4rem

  .permission-row
    display: flex
    align-items: center
    flex-wrap: nowrap
    gap: 0.5rem

    .info-btn
      width: 16px
      height: 16px

</style>

<style lang="sass">
.rounded-dialog
  max-width: 608px
  border-radius: 24px
  padding: 28px 44px

  .el-dialog__headerbtn
    top: 48px
    right: 64px
    
    .el-dialog__close
      color: #1F1F18

  .el-button, .button-default
    height: 48px
    max-height: 48px
    padding: 0.7em 1.2em
    border-radius: 12px

  .el-form-item__label
    padding: 0
  
  .el-form-item
    margin-bottom: 20px

  .edit-form-footer
    margin-top: 32px
    font-size: 16px
    
  .left
    display: inline
    float: left

  .right
    display: flex
    justify-content: flex-end

  .delete-user
    cursor: pointer
    display: flex
    align-items: center
    gap: .5rem
    color: #D92D20


</style>