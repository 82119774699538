<template>
  <div class="main-container">
    <div class="view-content">
      <h2 class="page-title">Welcome Back!</h2>
      <p class="sub-title">Metrics</p>

      <el-card v-if="requests">
        <div class="invoices-summary">
          <div class="owed">
            <div class="amount">{{ $formatCents(totalPayment) }}</div>
            <div class="type">Open Balance</div>
          </div>
          <div class="owed">
            <div class="amount">{{ totalInvoices }}</div>
            <div class="type">Open Invoices</div>
          </div>
          <div class="owed">
            <div class="amount" v-if="!!totalDays">{{ totalDays }} days</div>
            <div class="amount" v-else>-</div>
            <div class="type">Oldest Invoice</div>
          </div>
          <div class="owed points" v-if="points >= 0 && this.hasRewards" @click="goToRewardsPage">
            <div class="amount">
              {{ formatNumber(points) }} points
            </div>
            <div class="type">
              <div class="points-icon">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_5777_5006)">
                    <path
                      d="M9 4.5V16.5M9 4.5H6.34821C5.95749 4.5 5.58278 4.34196 5.30649 4.06066C5.03021 3.77936 4.875 3.39782 4.875 3C4.875 2.60218 5.03021 2.22064 5.30649 1.93934C5.58278 1.65804 5.95749 1.5 6.34821 1.5C8.41071 1.5 9 4.5 9 4.5ZM9 4.5H11.6518C12.0425 4.5 12.4172 4.34196 12.6935 4.06066C12.9698 3.77936 13.125 3.39782 13.125 3C13.125 2.60218 12.9698 2.22064 12.6935 1.93934C12.4172 1.65804 12.0425 1.5 11.6518 1.5C9.58929 1.5 9 4.5 9 4.5ZM15 8.25V14.1C15 14.9401 15 15.3601 14.8365 15.681C14.6927 15.9632 14.4632 16.1927 14.181 16.3365C13.8601 16.5 13.4401 16.5 12.6 16.5L5.4 16.5C4.55992 16.5 4.13988 16.5 3.81901 16.3365C3.53677 16.1927 3.3073 15.9632 3.16349 15.681C3 15.3601 3 14.9401 3 14.1V8.25M1.5 5.7L1.5 7.05C1.5 7.47004 1.5 7.68006 1.58175 7.84049C1.65365 7.98161 1.76839 8.09635 1.90951 8.16825C2.06994 8.25 2.27996 8.25 2.7 8.25L15.3 8.25C15.72 8.25 15.9301 8.25 16.0905 8.16825C16.2316 8.09635 16.3463 7.98161 16.4183 7.84049C16.5 7.68006 16.5 7.47004 16.5 7.05V5.7C16.5 5.27996 16.5 5.06994 16.4183 4.90951C16.3463 4.76839 16.2316 4.65365 16.0905 4.58175C15.9301 4.5 15.72 4.5 15.3 4.5L2.7 4.5C2.27996 4.5 2.06994 4.5 1.90951 4.58175C1.76839 4.65365 1.65365 4.76839 1.58175 4.90951C1.5 5.06994 1.5 5.27996 1.5 5.7Z"
                      stroke="#5A5838" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_5777_5006">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span style="margin-left: 8px;">Current Points</span>
              </div>
              <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7H17M17 7L11 1M17 7L11 13" stroke="#705323" stroke-width="1.6" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
        <div class="btn-container">
          <el-button class="view-invoices" type="primary" @click="showPopUp = true">
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.83268 1.51289V4.26659C9.83268 4.63995 9.83268 4.82664 9.90534 4.96925C9.96926 5.09469 10.0712 5.19667 10.1967 5.26059C10.3393 5.33325 10.526 5.33325 10.8993 5.33325H13.653M13.8327 6.65874V11.4666C13.8327 12.5867 13.8327 13.1467 13.6147 13.5746C13.4229 13.9509 13.117 14.2569 12.7407 14.4486C12.3128 14.6666 11.7528 14.6666 10.6327 14.6666H6.36602C5.24591 14.6666 4.68586 14.6666 4.25803 14.4486C3.88171 14.2569 3.57575 13.9509 3.384 13.5746C3.16602 13.1467 3.16602 12.5867 3.16602 11.4666V4.53325C3.16602 3.41315 3.16602 2.85309 3.384 2.42527C3.57575 2.04895 3.88171 1.74299 4.25803 1.55124C4.68586 1.33325 5.24591 1.33325 6.36602 1.33325H8.5072C8.99638 1.33325 9.24097 1.33325 9.47115 1.38851C9.67522 1.43751 9.87031 1.51831 10.0493 1.62797C10.2511 1.75166 10.424 1.92461 10.7699 2.27051L12.8954 4.39599C13.2413 4.7419 13.4143 4.91485 13.538 5.11668C13.6476 5.29563 13.7284 5.49072 13.7774 5.69479C13.8327 5.92496 13.8327 6.16955 13.8327 6.65874Z"
                stroke="#125F43" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Generate Report
          </el-button>
          <el-button class="view-invoices" type="primary" @click="viewInvoices">
            View All Invoices
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.66602 8H13.3327M13.3327 8L9.33268 4M13.3327 8L9.33268 12" stroke="#125F43"
                stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </el-button>
        </div>
      </el-card>
      <el-row class="overview-bottom-content">
        <el-col :span="12" class="upcoming-orders">
          <div class="upcoming-orders-title">
            <div>
              Upcoming orders
            </div>
            <div>
              <div class="switch-container">
                <toggle-switch :options="{
                  layout: {
                    color: '#6E6E68',
                    backgroundColor: '#EBEBE6',
                    squareCorners: false,
                    noBorder: true,
                    squareCorners: false,
                    fontWeightSelected: 'normal',
                    fontWeight: 500
                  },

                  size: {
                    fontSize: .75,
                    height: 2,
                    width: 8.125,
                  },

                  config: {
                    preSelected: 'Table',
                    disabled: false,
                    items: [
                      { name: 'List', value: 'List', color: '#1F1F18', backgroundColor: 'white' },
                      { name: 'Calendar', value: 'Table', color: '#1F1F18', backgroundColor: 'white' }
                    ]
                  }
                }" :disabled="false" @change="switchOrderView" />
              </div>
            </div>
          </div>
          <div class="overview-part" v-if="isList">
            <div v-if="allOrders.length > 0">
              <router-link :to="'/user/orders/' + allOrders[0].id">
                <el-card class="next-order">
                  <div class="next-order-id">Order #{{ allOrders[0].id }}</div>
                  <div class="next-order-name">{{ allOrders[0].request_name }}</div>
                  <div class="next-order-vendor-name">{{ allOrders[0].vendor_name }}</div>
                  <div class="next-order-date-container">
                    <div class="next-order-date-details">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14 6.66659H2M10.6667 1.33325V3.99992M5.33333 1.33325V3.99992M5.2 14.6666H10.8C11.9201 14.6666 12.4802 14.6666 12.908 14.4486C13.2843 14.2569 13.5903 13.9509 13.782 13.5746C14 13.1467 14 12.5867 14 11.4666V5.86659C14 4.74648 14 4.18643 13.782 3.7586C13.5903 3.38228 13.2843 3.07632 12.908 2.88457C12.4802 2.66659 11.9201 2.66659 10.8 2.66659H5.2C4.0799 2.66659 3.51984 2.66659 3.09202 2.88457C2.71569 3.07632 2.40973 3.38228 2.21799 3.7586C2 4.18643 2 4.74648 2 5.86659V11.4666C2 12.5867 2 13.1467 2.21799 13.5746C2.40973 13.9509 2.71569 14.2569 3.09202 14.4486C3.51984 14.6666 4.0799 14.6666 5.2 14.6666Z"
                          stroke="#1F1F18" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      {{ $formatDate(allOrders[0].delivery_date) }}
                    </div>
                    <div class="next-order-date-details">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_736_32127)">
                          <path
                            d="M8.00065 3.99992V7.99992L10.6673 9.33325M14.6673 7.99992C14.6673 11.6818 11.6826 14.6666 8.00065 14.6666C4.31875 14.6666 1.33398 11.6818 1.33398 7.99992C1.33398 4.31802 4.31875 1.33325 8.00065 1.33325C11.6826 1.33325 14.6673 4.31802 14.6673 7.99992Z"
                            stroke="#1F1F18" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                          <clipPath id="clip0_736_32127">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      {{ $formatTime(allOrders[0].delivery_time) }}
                    </div>
                  </div>
                </el-card>
              </router-link>
            </div>
            <!-- <order-mini v-for="x in upcoming.today" :order="x" :key="x.id"></order-mini> -->
            <el-card v-if="allOrders.length > 1">
              <order-mini v-for="y in allOrders.slice(1, 4)" :order="y" :key="y.id" showDate></order-mini>
            </el-card>
            <empty-block :imagePath="'Fruit'" title="There are no open orders available" :topTitle="true"
              v-if="allOrders && allOrders.length === 0" />
            <!-- <h3>Future Orders</h3>
          <order-mini v-for="z in upcoming.future" :order="z" :key="z.id" :show-date="true"></order-mini> -->
          </div>
          <el-card class="overview-part" v-else>
            <calendar></calendar>
          </el-card>
        </el-col>

        <el-col :span="12">
          <div class="overview-part" v-if="requests">
            <div class="pending-orders-title">Pending requests</div>
            <div class="requests-list">
              <div class="requests-item" v-for="x in requests.slice(0, 3)" :key="x.id">
                <router-link :to="'/user/requests/' + x.id">
                  <request-mini :request="x"></request-mini>
                </router-link>
              </div>
              <empty-block :imagePath="'Ordering'" title="There are no open requests available" :topTitle="true"
                v-if="requests && requests.length === 0" />
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="routes-cotainer">
        <router-link to="orders">
          <base-button>
            <div class="view-all-orders-button">
              View All Orders
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.66602 8H13.3327M13.3327 8L9.33268 4M13.3327 8L9.33268 12" stroke="#125F43"
                  stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </base-button>
        </router-link>
        <router-link to="requests">
          <base-button>
            <div class="view-all-orders-button">
              View All Requests
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.66602 8H13.3327M13.3327 8L9.33268 4M13.3327 8L9.33268 12" stroke="#125F43"
                  stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </base-button>
        </router-link>
      </div>
      <pop-up :showPopUp="showPopUp" @closePopUp="showPopUp = false" title="Generate Report">
        <div>
          <template v-if="!requests">
            <h4>Loading...</h4>
          </template>
          <template v-else>
            <el-row :gutter="$rowGutter">
              <el-col class="count count-range" :span="24">
                <div class="overview__daterange user-daterange">
                  <el-date-picker v-model="dateRange" format="MM-dd-yyyy" value-format="yyyy-MM-dd" type="daterange"
                    range-separator="-" start-placeholder="Start date" end-placeholder="End date"></el-date-picker>
                  <div class="generate-report-container">
                    <el-button type="primary" @click="getInvoicesReports" :loading="submitting">
                      Generate report
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.66699 8H13.3337M13.3337 8L9.33366 4M13.3337 8L9.33366 12" stroke="white"
                          stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </template>
        </div>
      </pop-up>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import OrderMini from './blocks/OrderMini.vue'
import Calendar from '../common/UserCalendar.vue'
import RequestMini from './blocks/RequestMini.vue'
import BaseButton from './blocks/BaseButton.vue'
import PopUp from '@/common/PopUp.vue'
import EmptyBlock from './blocks/EmptyBlock.vue'

function data() {
  let now = new Date()
  let format = 'YYYY-MM-DD'
  let todayDate = moment(now, format)
  let tomorrowDate = moment(new Date((new Date()).setDate(now.getDate() + 1)), format)
  return {
    format,
    todayDate,
    tomorrowDate,
    showReport: false,
    mutableList: this.isList,
    dateRange: [],
    submitting: false,
    showPopUp: false,
    upcoming: {
      today: [],
      tomorrow: [],
      future: [],
    },
    allOrders: [],
    requests: null,
    totalInvoices: 0,
    totalPayment: 0,
    totalDays: null,
    user: null,
    user_permissions: {
      worldwide_view: false,
      permissions: {}
    },
    points: 0,
    hasRewards: false
  }
}

function created() {
  this.$simplecater.get('/orders/open').then((r) => {
    let orders = _.chain(r.data.data).sortBy('delivery_time').sortBy('delivery_date').value()
    let todayOrders = _.filter(orders, (x) => moment(x.delivery_date, this.format).isSame(this.todayDate, 'day'))
    let tomorrowOrders = _.filter(orders, (x) => {
      return moment(x.delivery_date, this.format).isSame(this.tomorrowDate, 'day')
    })
    let futureOrders = _.filter(orders, (x) => (!moment(x.delivery_date, this.format).isSame(this.todayDate, 'day') && !moment(x.delivery_date, this.format).isSame(this.tomorrowDate, 'day')))
    this.upcoming = {
      today: todayOrders,
      tomorrow: tomorrowOrders,
      future: futureOrders
    }
    this.allOrders = orders
  })

  this.$simplecater.get('/requests/open').then((r) => {
    this.requests = _.sortBy(r.data.data, 'delivery_date')
  })

  this.$simplecater.get('/invoices/open').then((r) => {
    let invoices = r.data.data
    this.totalPayment = invoices.reduce((acc, { total_cents }) => (acc + total_cents), 0)
    const findOldest = invoices.reduce((acc, curr) => {
      if (!acc?.delivery_date) return curr
      const accDate = new Date(acc.delivery_date)
      const currDate = new Date(curr.delivery_date)
      if (accDate.getTime() < currDate.getTime()) return acc
      return curr;
    }, {})

    const now = new Date()
    const todayDate = moment(`${now.getFullYear()}-${now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1}-${now.getDate()}`, this.format)
    const oldestDate = moment(findOldest.delivery_date, this.format)
    const days = oldestDate.diff(todayDate, 'days')
    const totalDaysRow = oldestDate.diff(todayDate, 'days')
    if (days < 0) (this.totalDays = Math.abs(totalDaysRow))
    this.totalInvoices = invoices.length
  })

  this.$simplecater.get('/account').then((r) => {
    this.user = r.data.data
    this.getPermissionsList().then(() => {
      this.getUserPermissions().then(() => {
        this.hasRewards = this.user_permissions.permissions.access_points

        if (this.hasRewards) {
          this.$simplecater.get(`points/`).then((r) => {
            this.points = r.data.data.amount;
          }).catch(() => {
            this.$message.error("Failed to load the customer's points");
          })
        }
      })
    })
  }).catch(() => {
    this.$message.error("Failed to load the user information")
  })
}

let methods = {
  viewInvoices() {
    this.$router.push('/user/billing')
  },
  switchOrderView() {
    this.$emit('changeList')
  },
  getInvoicesReports() {
    this.submitting = true;
    const requestString = this.dateRange.length ? `?start_date=${this.dateRange[0]}&end_date=${this.dateRange[1]}` : '';
    this.$simplecater.get(`/statistics/invoices_report/${requestString}`).then(r => {
      this.submitting = false;
      if (r.status === 204) {
        this.$message.warning('No data for this date range');
      } else {
        this.downloadCsv(r.data);
      }
    }).catch(() => {
      this.$message.error('Failed loading data');
    })
  },
  downloadCsv(data) {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
    hiddenElement.target = '_blank';
    hiddenElement.download = `invoices_range${this.dateRange[0]}/${this.dateRange[1]}.csv`;
    hiddenElement.click();
  },
  goToRewardsPage() {
    this.$router.push('/user/rewards')
  },
  formatNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  async getPermissionsList() {
    const target = `/accounts/${this.user.account_id}/customers/permissions-list`
    
    await this.$simplecater.get(target).then((r) => {
      const permissions = r.data.data.reduce((acc, permission) => {
        if (permission === 'worldwide_view') {
          this.user_permissions.worldwide_view = false;
        } else {
          acc[permission] = false;
        }
        return acc;
      }, {});
      
      this.user_permissions = {
        worldwide_view: false,
        permissions
      };
    }).catch((e) => {
      this.$message.error("Failed to load the user permissions")
      console.log(e)
    })
  },

  async getUserPermissions() {
    const target = `/accounts/${this.user.account_id}/customers/${this.user.id}/permissions`;
    await this.$simplecater.get(target).then((r) => {
      const userPerms = r.data.data;
      if (userPerms.worldwide_view !== undefined) {
        this.user_permissions.worldwide_view = userPerms.worldwide_view;
      }
      if (userPerms.permissions) {
        Object.keys(userPerms.permissions).forEach(key => {
          if (Object.prototype.hasOwnProperty.call(this.user_permissions.permissions, key)) {
            this.user_permissions.permissions[key] = userPerms.permissions[key];
          }
        });
      }
    }).catch(() => {
      this.$message.error("Failed to load the user permissions");
    });
  },
}
const props = {
  isList: {
    type: Boolean,
    required: false,
    default: false,
  }
}
let components = { OrderMini, RequestMini, Calendar, BaseButton, PopUp, EmptyBlock }

let name = 'Overview'

export default { data, methods, created, components, props, name }

</script>

<style scoped lang="sass">

h3
  position: relative
  padding: 0.3em 0
  margin: 1em 0 0.5em
  &:first-child
    margin-top: 0
  &:before
    position: absolute
    content: ''
    bottom: 0
    left: 0
    width: 2rem
    height: 1px
    background: $colorPrimary
  small
    display: block
    color: #888
    font-weight: 400
    font-size: 0.6em
    text-transform: uppercase

.el-row
  display: flex
  .el-col-12
    & > div
      height: auto

.invoices-summary
  display: flex
  gap: .9375rem
  .owed
    width: 100%
    padding: 1.5rem
    background: #FAFAF5
    border-radius: 12px
    font-size: 2.5rem
    .amount
      font-size: 1.4rem
      font-weight: 600
      margin-bottom: 16px
    .type
      color: #888
      font-size: 0.8rem
  .points
    background: #E3DC82
    cursor: pointer
    .type
      color: #5A5838
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      .points-icon
        display: flex
        flex-direction: row
        align-items: center

.requests-list
  display: flex
  flex-direction: column
  .requests-item
    flex: 0 0 50%
    padding-top: 0.8rem
    .el-card
      // margin-top: 1rem
      box-shadow: none
      height: 100%
      cursor: pointer
      transition: transform 0.1s ease-in-out
      position: relative
      &:hover
        transform: scale(1.03)
.view-invoices
  padding: .625rem 1.5rem
  gap: .5rem
  height: 3rem
  background: #F7FDF9
  border-radius: .75rem
  color: #125F43
  border-color: #125F43
  width: 100%
  font-size: 500
  font-family: 'Inter'
  font-size: .875rem

.next-order
  margin-bottom: .6rem
  &-id
    font-size: .875rem
    line-height: 1.25rem
    letter-spacing: -0.03em
    color: #125F43
  &-name
    color: #1F1F18
    font-size: 1.5rem
    margin: .4rem 0
    font-weight: 500
  &-vendor-name
    color: #1F1F18
    font-size: 1rem
    margin-bottom: 1rem
    opacity: 0.64
  &-date-container
    display: flex
    flex-direction: row
    gap: .75rem
  &-date-details
    display: flex
    aling-items: center
    font-weight: 500
    font-size: .875rem
    gap: .375rem

.overview__daterange
  .el-date-editor
    width: 100%
    border-radius: 10px
.view-content .el-row:last-of-type
  margin-top: 20px
.button-wrapper
  margin-left: calc(100% - 300px)
.btn-container
  display: flex
  flex-direction: row
  justify-content: space-between
  margin-top: 1rem

.overview-bottom-content
  margin-top: 2.5rem

.upcoming-orders
  margin-right: 1.5rem
  &-title
    display: flex
    flex-direction: row
    align-items: center
    margin-bottom: 1rem
    justify-content: space-between

.switch-container
  background: #EBEBE6
  border-top: .25rem solid #EBEBE6
  border-left: .25rem solid #EBEBE6
  border-radius: .25rem
  border-right: .25rem solid #EBEBE6

.pending-orders-title
  height: 2.75rem
  display: flex
  align-items: center

.view-all-orders-button
  display: flex
  align-items: center
  justify-content: center
  gap: .375rem

.overview-part
  margin-bottom: 1.5rem
  height: 100%

.generate-report-popup
  position: absolute
  margin-left: auto
  margin-right: auto
  left: 0
  right: 0
  text-align: center

.generate-report-container
  width: 100%
  display: flex
  justify-content: flex-end
  flex-direction: row

.sub-title
  font-size: 1rem
  line-height: 1.5rem
  letter-spacing: -0.03em
  color: #A2A29D
  margin-top: 0

.page-title
  margin-bottom: 1.875rem

.routes-cotainer
  display: flex
  flex-direction: row
  width: 100% 
  justify-content: space-between
  gap: 1.5rem  

</style>