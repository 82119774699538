<template lang='pug'>
.main-container
  .view-content
    div
      el-card(v-loading="loading")
        div(slot='header')
          i.mat-icon person
          | Account

        el-form(ref="form", :model="account", :rules="rules", label-width="125px")
          el-form-item(label="Name", prop="name")
            el-input(v-model="account.name")

          el-form-item(label="Activate Rewards", prop="rewards")
            el-checkbox(v-model="account.rewards")

          el-form-item(align="right")
            el-button(type="primary", @click="submitForm", :style="{ marginLeft: '10px' }") Save
</template>

<script>
export default {
  name: 'AccountEdit',

  props: {
    accountId: {
      type: [String, Number],
      required: true
    }
  },

  data() {
    return {
      loading: false,
      account: {
        name: '',
        rewards: false,
      },
      rules: {
        name: [
          { required: true, message: 'Please input account name', trigger: 'blur' },
          { min: 2, message: 'Length should be at least 2 characters', trigger: 'blur' }
        ],
      }
    }
  },

  methods: {
    async getAccountDetails() {
      try {
        this.loading = true
        const { data } = await this.$simplecater.get(`/accounts/${this.accountId}`)
        this.account.name = data.data.name
        this.account.rewards = data.data.rewards
      } catch (error) {
        this.$message.error('Failed to load account details')
      } finally {
        this.loading = false
      }
    },

    async submitForm() {
      try {
        await this.$refs.form.validate()
        this.loading = true
        const { data } = await this.$simplecater.put(`/accounts/${this.accountId}`, {
          name: this.account.name,
          rewards: this.account.rewards
        })
        this.account.name = data.data.name
        this.account.rewards = data.data.rewards
        this.$message.success('Account updated successfully')
      } catch (error) {
        this.$message.error(error.response?.data?.message || 'Failed to update account')
      } finally {
        this.loading = false
      }
    }
  },

  created() {
    this.getAccountDetails()
  }
}
</script>

<style lang="sass" scoped>
.el-form
  max-width: 600px
  margin: 20px 0
</style>
