<style scoped lang="sass">

.notes-divider
  border-bottom: 1px solid #ddd
  margin: 0.8rem 0 0.5rem
</style>
<template lang='pug'>
el-form(
  v-loading='loading'
  label-position='top', :model='estimate', v-if='estimate != null', ref='form',
  :rules='rules'
)
  el-row(:gutter='$rowGutter', v-if="remoteId")
    el-col(:span='24', align="right")
        el-button(type="text", @click="$openInBlank(`/print/estimate/${remoteId}`)") Printable version

  el-row(:gutter='$rowGutter')

    el-col(:span='24', v-if='estimate.customer_notes')
      i.mat-icon.cl-primary speaker_notes
      strong  Customer Requested Changes:
      blockquote.classic.per-text {{ estimate.customer_notes }}
      .notes-divider

    el-col(:span='6')
      el-form-item(label='Name', prop='name')
        el-input(v-model='estimate.name')

    el-col(:span='5')
      el-form-item(label='Cuisine', prop='cuisine_id')
        el-select(placeholder='Select a cuisine', v-model='estimate.cuisine_id')
          el-option(
            v-for='item in cuisines', :key='item.id',
            :label='item.name', :value='item.id'
                )
    el-col(:span='8')
      div(v-if='estimate.cuisine_id == null')
        | &nbsp;
      el-form-item(v-else, label='Vendor', prop='vendor_id')
        el-select(placeholder='Select a vendor', v-loading='loadingVendors', v-model='estimate.vendor_id', filterable, reserve-keyword)
          el-option(
            v-for='item in vendors', :key='item.id',
            :label='item.name', :value='item.id'
          )
            span(:style="{ fontWeight: item.global_discount_percentage >= 10 ? 'bold' : 'normal', color: item.global_discount_percentage >= 10 ? '#125F43' : 'inherit'  }") {{ item.name }}

    el-col(:span='5')
      el-form-item(label='Budget use')
        el-progress(:percentage='budgetUse')
        | {{$formatCents(totalUsedCents)}} / {{$formatCents(totalBudgetCents)}}

  el-row(:gutter='$rowGutter')

    el-col(:span='10')
      el-form-item(label='Products')
        items-selector(
          v-if='estimate.vendor_id != null', :vendor-id='estimate.vendor_id',
          ref='itemsSelector', :eventBus='eventBus',
          :backend-items='backendItems', :backend-vendor='backendVendor', :is-new-estimate='!estimateId'
        )
    el-col(:span='14', v-if="windowWidth >= 1200")
      //- el-form-item(prop='selectedProducts')
      el-form-item
      items-builder(
        :estimate-id='estimateId', ref='itemsBuilder',
        :eventBus='eventBus', :taxRate='taxRate', @change='onTotalChange'
        @tips-update='onTipsUpdate($event)'
        :fullOrder='fullOrder'
      )
  el-row(:gutter='$rowGutter', v-if="windowWidth < 1200")
    el-col
      el-form-item
      items-builder(
        :estimate-id='estimateId', ref='itemsBuilder',
        :eventBus='eventBus', :taxRate='taxRate', @change='onTotalChange' @tips-update='onTipsUpdate($event)'
        :fullOrder='fullOrder'
      )

  el-row.buttons-section(:gutter='$rowGutter')
    el-col(:span='8', :offset='16', style='text-align: right')
      el-button(type='primary', @click='onSubmit', :loading='submitting') Save
</template>



<script>
import ItemsSelector from './ItemsSelector.vue'
import ItemsBuilder from './ItemsBuilder.vue'
import _ from 'underscore'
import Vue from 'vue'

function data(){
  return {
    loading: false,
    estimateId: this.remoteId,
    windowWidth: window.innerWidth,
    submitting: false,
    estimate: null,
    vendors: null,
    backendVendor: null,
    backendItems: null,
    eventBus: new Vue(),
    totalUsedCents: 0,
    // productSelection: null,
    rules: {
      name: { required: true, trigger: 'blur' },
      cuisine_id: { required: true, trigger: 'blur', message: 'Please select an option' },
      vendor_id: { required: true, trigger: 'change', message: 'Please select an option' },
      //selectedProducts: { validator: validateSelection.bind(this) }
    },
    tips: 0
  }
}

function created(){
  if(this.estimateId){
    this.loadFullEstimate()
  }else{
    this.estimate = {
      name: `Menu ${this.index + 1}`
    }
  }
}


function loadVendors(newCuisine){
  const cuisineVendors = `/cuisines/${newCuisine}/vendors`
  this.$simplecater.get(cuisineVendors).then((r)=>{
    if (this.estimate.cuisine_id == newCuisine) {
      this.vendors = r.data.data
    }
  }).catch(()=>{
    this.$message.error("Failed to load the list of vendors")
  })
}

function loadFullEstimate(){
  this.loading = true;
  const fullEstimate = `/estimates/${this.estimateId}`
  this.$simplecater.get(fullEstimate).then((r)=>{
    if (this.estimateId == r.data.data.id) {
      const estimateData = r.data.data
      const items = estimateData.items
      // Remove items so that it doesn't get into the comps data
      delete estimateData.items
      // Update the component
      this.estimate = estimateData
      this.backendItems = items
      this.backendVendor = estimateData.vendor_id;
      this.loading = false;
    }
  }).catch(()=>{
    this.$message.error("Failed to load the list of products")
  })
}

function updateEstimate(estimate){
  const estimateUrl = `/estimates/${this.estimateId}`
  return this.$simplecater.put(estimateUrl, estimate)
}

function createEstimate(estimate){
  return this.$simplecater.post('/estimates', estimate)
}

function setItems(items){
  const itemsUrl = `/estimates/${this.estimateId}/items`
  return this.$simplecater.put(itemsUrl, {items: items})
}

function submit(){
  console.log("Submitting");
  this.submitting = true;
  this.estimate.tips = +this.tips
  const finalItems = this.$refs.itemsBuilder.buildItemsForSubmit();
  const estimate = Object.assign({}, this.estimate, {request_id: this.requestId})
  if ( this.estimateId ) {
    return Promise.all([
      this.updateEstimate(estimate), this.setItems(finalItems)
    ]).then(()=>{
      if (this.update) {
        this.update(this.estimate.vendor_id);
      } else {
        this.$message("The estimate was saved");
        this.notifyParent()
      }
      this.submitting = false;
    }).catch( () => {
      this.$message.error("Saving failed");
      this.submitting = false
    })
  } else {
    this.createEstimate(estimate).then((r)=>{
      this.estimateId = r.data.data.id
      this.estimate.id = r.data.data.id
      this.setItems(finalItems).then(()=>{
        this.$message("The estimate was created")
        this.submitting = false
        this.notifyParent()
      }).catch(()=>{
        this.$message.error("Saving the items failed")
        this.submitting = false
      })
    }).catch(()=>{
      this.$message.error("Creating the estimate failed")
      this.submitting = false
    })
  }    
}

function onSubmit(){
  this.$refs.form.validate((valid) => {
    if(valid){
      this.submit()
    }
  })
}

const computed = {
  loadingVendors(){
    return this.estimate.cuisine_id != null && this.vendors == null
  },
  budgetUse(){
    const percentage = Math.ceil(100*this.totalUsedCents/this.totalBudgetCents)
    return percentage > 100 ? 100 : percentage
  }
}

function notifyParent(){
  const items = []
  let estimate = Object.assign({},this.estimate, {items})
  estimate = _.extend({}, estimate)
  this.$emit('change', this.index, estimate)
}

function onTotalChange (newTotal) {
  console.log("****RECEIVING NEW TOTAL", newTotal)
  this.totalUsedCents = newTotal
}

function onResize() {
  this.windowWidth = window.innerWidth
}

function onTipsUpdate(tips) {
 this.tips = tips.toFixed()
 this.$emit('tips-update', tips.toFixed())
}

const props = {
  remoteId: { required: false },
  requestId: { required: true },
  index: { required:  true },
  cuisines: { required: true },
  totalBudgetCents: { required: true },
  taxRate: { required: true },
  update: { required: false },
  fullOrder: { required: false }
}

const watch = {
  'estimate.cuisine_id': function(newCuisine, oldCuisine){
    if (newCuisine != oldCuisine) this.vendors = null
    if (newCuisine != null) this.loadVendors(newCuisine)
  }
}

const components = { ItemsSelector, ItemsBuilder }

const methods = {
  created, loadVendors, loadFullEstimate, onSubmit, createEstimate,
  notifyParent, submit, updateEstimate, setItems, onTotalChange,
  onResize, onTipsUpdate
}

function mounted() {
  this.$nextTick(() => {
    window.addEventListener('resize', this.onResize);
  })
}

function beforeDestroy() {
  window.removeEventListener('resize', this.onResize); 
}

export default { data, mounted, beforeDestroy, methods, props, watch, created, computed, components }
</script>
