<template lang="pug">
.main-container
  .view-content
    div
      el-card
        div(slot='header')
          i.mat-icon person
          | Account Customers
          el-button(type="primary", @click="startCreatingCustomer", style='float: right')
            i.mat-icon add_circle
            | New customer

        el-table(:data='customers', v-loading='customers == null')
          el-table-column(type='expand')
            template(slot-scope='props')
              customer-detail(:backend-data='props.row', @deleted='getCustomers', :key='props.row.account_name', ref='customerDetail')
          el-table-column(label='Account name')
            template(slot-scope='scope')
              div(v-if='editingId !== scope.row.id')
                | {{scope.row.account_name}}
                a(href='#', @click.prevent='onEditClick(scope.row)')
                  i.mat-icon.edit-icon edit
              div(class='inline-form', v-else)
                el-form(:model='editForm', @submit.prevent='onSave(scope.row)')
                  el-form-item
                    el-input(v-model='editForm.account_name', placeholder='Account Name', @keyup.enter='onSave(scope.row)', ref='editInput')
                  el-button(type='primary', @click='onSave(scope.row)') Save
                  el-button(@click='onCancel(scope.row)') Cancel
          el-table-column(label='Location')
            template(slot-scope='scope', v-if='scope.row.city')
              | {{scope.row.city}}, {{scope.row.state}} {{scope.row.zip_code}}

        el-col()
          el-pagination( 
            v-if="pagination.pages > 1"
            @current-change="onPaginationChange"
            :page-size="pagination.per_page", 
            :pager-count="5", 
            layout="prev, pager, next", 
            :total="pagination.total")

      el-dialog(:visible.sync='creatingCustomer', title='Create customer')
        el-form(ref="newCustomer", :model="newCustomer", label-width="150px", v-if='newCustomer != null', :rules="createCustomerRules")
          el-form-item(label='Account name', prop="account_name")
            el-input(placeholder="", v-model='newCustomer.account_name')
          el-form-item(label='Email', prop="email")
            el-input(placeholder="", v-model='newCustomer.email')
          el-form-item(label='Password', prop="password")
            el-input(placeholder="", v-model='newCustomer.password')
          el-form-item(align="right")
            el-button(type='primary', @click='submitForm')
              | Create customer

  router-view
</template>

<script>
/**
 * AccountCustomers component displays and manages customers for a specific account.
 * It requires an accountId prop and fetches customers using the 
 * /accounts/{accountId}/customers endpoint.
 */
export default {
  name: "AccountCustomers",
  
  props: {
    accountId: {
      type: [String, Number],
      required: true
    }
  },

  data() {
    return {
      customers: null,
      creatingCustomer: false,
      newCustomer: null,
      createCustomerRules: {
        email: [
          {
            required: true,
            message: "Please input the email",
            trigger: "blur"
          },
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"]
          }
        ],
        account_name: [
          {
            required: true,
            message: "Please input the account name",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "Please input the password",
            trigger: "blur"
          },
          {
            min: 8,
            message: "Password should contain at least 8 characters",
            trigger: ["blur", "change"]
          }
        ]
      },
      pagination: {
        per_page: null,
        current: null,
        pages: null,
        total: null,
      },
      editingId: null,
      editForm: {
        account_name: ''
      }
    };
  },

  components: { 
    CustomerDetail: () => import("./CustomerDetail.vue") 
  },

  methods: {
    async getCustomers(current_page) {
      !!this.customers && (this.customers = null);
      let params = current_page ? { params: { page: current_page } } : {};
      
      const url = `/accounts/${this.accountId}/customers`;

      try {
        const { data, pagination } = (
          await this.$simplecater.get(url, params)
        ).data;
        
        this.customers = data;
        this.pagination = pagination;
      } catch (error) {
        this.$message.error("Failed to fetch account customers");
        console.error("Error fetching account customers:", error);
      }
    },

    submitForm() {
      this.$refs.newCustomer.validate(valid => {
        if (!valid) return false;
        this.createCustomer();
      });
    },

    createCustomer() {
      // Associate the new customer with the account
      const customerData = {
        ...this.newCustomer,
        account_id: parseInt(this.accountId)
      };

      return this.$simplecater
        .post(`/customers`, customerData)
        .then(() => {
          // const customer = r.data.data;
          this.getCustomers();
          this.creatingCustomer = false;
          this.$message.success("Customer created successfully");
        })
        .catch(this.$handleErrorFor("create customer").bind(this));
    },

    startCreatingCustomer() {
      this.newCustomer = {};
      this.creatingCustomer = true;
    },

    onPaginationChange(current_page) {
      this.getCustomers(current_page);
    },

    onEditClick(row) {
      this.editingId = row.id
      this.editForm.account_name = row.account_name
      // Focus the input after it's rendered
      this.$nextTick(() => {
        if (this.$refs.editInput) {
          this.$refs.editInput.focus()
        }
      })
    },

    onCancel(row) {
      this.editingId = null
      this.editForm.account_name = row.account_name
    },

    async onSave(row) {
      try {
        const target = `/customers/${row.id}`
        await this.$simplecater.put(target, { account_name: this.editForm.account_name })
        
        row.account_name = this.editForm.account_name
        this.editingId = null
        this.$message("The customer has been saved")
        
        // Find and reload the expanded customer detail if it exists
        const customerDetail = this.$refs.customerDetail
        if (Array.isArray(customerDetail)) {
          const expandedDetail = customerDetail.find(detail => detail.backendData.id === row.id)
          if (expandedDetail && expandedDetail.getCustomer) {
            expandedDetail.getCustomer()
          }
        }
      } catch (err) {
        let errorString = (err) ? (': ' + JSON.stringify(err)) : ''
        this.$message.error("Saving failed" + errorString)
      }
    }
  },

  watch: {
    $route() {
      this.getCustomers();
    },

    accountId: {
      immediate: true,
      handler() {
        this.getCustomers();
      }
    }
  },

  created() {
    this.getCustomers();
  }
};
</script>

<style scoped lang="sass">
.inline-form
  form
    display: flex
    align-items: center

    .el-form-item
      margin: 0 10px 0 0
      width: 400px

.edit-icon
  font-size: 16px
  margin-left: 8px
  color: #909399
  cursor: pointer
  
  &:hover
    color: #409EFF
</style>
